import React, { useContext, useEffect, useState, useCallback } from 'react'
import {
  Table,
  Button,
  Space,
  DatePicker,
  Select,
  Modal,
  Form,
  Input,
  message,
} from 'antd'
import {
  ReservationCollectionListener,
  callOperaReservationCollectionSaveListenerApi,
  callOperaCloudReservationCollectionSaveListenerApi,
  unsubscribeReservations,
  sendEmailByEmailId,
} from '../../../../services/pms'
import Header from '../../../Common/Header/Header'
import SideMenu from '../../../Common/Sidemenu/Sidemenu'
import { useDispatch, useSelector } from 'react-redux'
import PageNamecard from '../../../Common/PageNameCard/PageNameCard'
import {
  PaginationOptions,
  pendingLable,
  emailSentLable,
} from '../../../../config/constants'
import { getImage } from '../../../../config/utils'
import CustomAlert from '../../../Common/CustomAlert/CustomAlert'
import { useCustomI18NTranslatorHook } from '../../../../utility/globalization'
import { AuthContext } from '../../../../Router/AuthRouteProvider'
import ConfirmationDialog from '../../../Common/ConfirmationDialog/ConfirmationDialog'
import crypto from 'crypto-js'
import { db } from '../../../../config/firebase'
import { Collections } from '../../../../config/constants'
import moment from 'moment'
import SectionLoader from '../../../Common/Loader/SectionLoader'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

const generateEmailVariables = reservation => {
  const queryParams = `hotel=${encodeURIComponent(
    'our-hotel-resort-spa-dei'
  )}&uniqueId=${encodeURIComponent(reservation?.UniqueID)}`
  const encryptedParams = crypto.AES.encrypt(
    queryParams,
    'pms-precheckin-key'
  ).toString()
  return {
    '%name%': reservation?.guestName,
    '%link%': `https://dev-guest.inplass.online/prechecking?${encryptedParams}`,
    '%checkin%': `${reservation?.checkinDate}`,
    '%logo%': `gs://inplass-dev-new.appspot.com/profileImages/lin5qqpa.jpg`,
  }
}

const getEmailButtonProps = emailAction => {
  console.log('sdsdf', emailAction)
  switch (emailAction) {
    case emailSentLable:
      return { text: 'Sent', disabled: true, showButton: true }
    case pendingLable:
      return { text: 'Send', disabled: false, showButton: true }
    default:
      return { text: 'Send', disabled: false, showButton: false }
  }
}

const PMSreserve = () => {
  const { hotelId, hotelInfo } = useContext(AuthContext)
  const [showLoader, setShowLoader] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [translateTextI18N] = useCustomI18NTranslatorHook()
  const reservations = useSelector(state => state.reservations)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [selectedReservation, setSelectedReservation] = useState(null)
  const [email, setEmail] = useState('')
  const [variables, setVariables] = useState('')
  const [emailTemp, setEmailTemp] = useState('')
  const [processedReservations] = useState(new Set())
  const dispatch = useDispatch()
  const [emailTimePeriod, setEmailTimePeriod] = useState(null)
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const [form] = Form.useForm()

  const hotelPmsIntergationType = hotelInfo?.pmsIntergationType

  // State for filter values
  const [filters, setFilters] = useState({
    reservationId: '',
    bookingReference: '',
    guestName: '',
    arrivalDate: null,
  })

  // State for unique reservation IDs, booking references, and guest names
  const [reservationIds, setReservationIds] = useState([])
  const [bookingReferences, setBookingReferences] = useState([])
  const [guestNames, setGuestNames] = useState([])

  // Date format for DatePicker
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY']

  // Fetch unique reservation IDs, booking references, and guest names
  useEffect(() => {
    const uniqueReservationIds = [
      ...new Set(reservations.map(res => res.UniqueID)),
    ].map(id => ({ label: id, value: id }))

    const uniqueBookingReferences = [
      ...new Set(reservations.map(res => res.bookingRefrence)),
    ].map(ref => ({ label: ref, value: ref }))

    const uniqueGuestNames = [
      ...new Set(reservations.map(res => res.guestName)),
    ].map(name => ({ label: name, value: name }))

    setReservationIds(uniqueReservationIds)
    setBookingReferences(uniqueBookingReferences)
    setGuestNames(uniqueGuestNames)
  }, [reservations])

  // Filter reservations based on filter values
  const filteredReservations = reservations.filter(reservation => {
    const arrivalDateMatch = filters.arrivalDate
      ? moment(reservation.checkinDate).isSame(filters.arrivalDate, 'day')
      : true

    return (
      (!filters.reservationId ||
        reservation.UniqueID.includes(filters.reservationId)) &&
      (!filters.bookingReference ||
        reservation.bookingRefrence.includes(filters.bookingReference)) &&
      (!filters.guestName ||
        reservation.guestName.includes(filters.guestName)) &&
      arrivalDateMatch
    )
  })

  console.log(filteredReservations, 'filteredReservations')

  // Reset filters
  const resetFilter = () => {
    setFilters({
      reservationId: '',
      bookingReference: '',
      guestName: '',
      arrivalDate: null,
    })
  }

  const handleArrivalDateChange = date => {
    setFilters({ ...filters, arrivalDate: date })
  }

  // --- Modal Handlers ---
  const showAddModal = () => {
    setIsAddModalVisible(true)
  }
  

  const handleAddOk = async () => {
    try {
      const values = await form.validateFields()
      setShowLoader(true)

      // Generate unique reservation ID
      const uniqueReservationId = uuidv4().substring(0, 6).toUpperCase()

      // Format dates for SOAP template
      const arrivalDate = moment(values.arrivalDate).format('YYYY-MM-DD')
      const departureDate = moment(values.arrivalDate)
        .add(1, 'days')
        .format('YYYY-MM-DD')

      // Generate SOAP XML using form values
      const soapTemplate = `<?xml version="1.0" encoding="UTF-8"?>
<soap-env:Envelope xmlns:soap-env="http://schemas.xmlsoap.org/soap/envelope/">
  <soap-env:Header xmlns:soap-env="http://schemas.xmlsoap.org/soap/envelope/">
    <wsse:Security xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:wsse="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd" soap:mustunderstand="1">
      <wsse:UsernameToken>
        <wsse:Username>
        </wsse:Username>
        <wsse:Password type="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-username-token-profile-1.0#passwordtext">
        </wsse:Password>
      </wsse:UsernameToken>
    </wsse:Security>
  </soap-env:Header>
  <soap-env:Body xmlns:soap-env="http://schemas.xmlsoap.org/soap/envelope/">
    <OTA_HotelResNotifRQ Version="1" EchoToken="20230507165140749999" TimeStamp="2023-05-09T16:51:40.000+05:30" xmlns="http://www.opentravel.org/OTA/2003/05">
      <HotelReservations>
        <HotelReservation ResStatus="In-house" CreatorID="TARUN" CreateDateTime="2025-04-09T15:58:40.000+05:30" LastModifierID="TARUN" LastModifyDateTime="2023-05-07T16:24:59.000+05:30">
          <POS>
            <Source>
              <RequestorID ID="WINCLOUDPMS"/>
            </Source>
            <Source>
              <BookingChannel Type="7" Primary="true">
                <CompanyName />
              </BookingChannel>
            </Source>
          </POS>
          <UniqueID ID="${uniqueReservationId}" />
          <RoomStays>
            <RoomStay SourceOfBusiness="Direct" MarketCode="House Guest" roomreservation_id="52">
              <RoomRates>
                <RoomRate EffectiveDate="${arrivalDate}" ExpireDate="${departureDate}" RoomTypeCode="EHP" RatePlanCode="R" NumberOfUnits="1" RoomID="000sdsdfs">
                  <Rates>
                    <Rate UnitMultiplier="1" EffectiveDate="${arrivalDate}" ExpireDate="${departureDate}">
                      <Base CurrencyCode="" AmountBeforeTax="0.00" />
                      <Total CurrencyCode="" AmountBeforeTax="0.00" />
                    </Rate>
                  </Rates>
                </RoomRate>
              </RoomRates>
              <GuestCounts>
                <GuestCount Count="1" AgeQualifyingCode="10" />
              </GuestCounts>
              <TimeSpan End="${departureDate}" Start="${arrivalDate}" />
              <Total CurrencyCode="" AmountBeforeTax="0.00" />
              <ResGuestRPHs>
                <ResGuestRPH RPH="1" />
              </ResGuestRPHs>
            </RoomStay>
          </RoomStays>
          <ResGuests>
            <ResGuest PrimaryIndicator="true" ResGuestRPH="1">
              <Profiles>
                <ProfileInfo>
                  <UniqueID ID_Context="PROPERTY" ID="34" Type="1" />
                  <Profile ProfileType="1" ShareAllMarketInd="true" ShareAllOptOutInd="false">
                    <Customer CustomerType="Single Lady">
                      <PersonName Language="en" NameType="2">
                        <NamePrefix>Mr.</NamePrefix>
                        <GivenName>${values.guestName}</GivenName>
                        <Surname>k</Surname>
                      </PersonName>
                      <Telephone PhoneNumber="" PhoneTechType="5" />
                      <Email>${values.email}</Email>
                      <Address>
                        <CityName>ACHROL</CityName>
                        <BookingRefrenceId>${values.bookingReference}</BookingRefrenceId>
                        <CountryName Code="IN">INDIA</CountryName>
                      </Address>
                    </Customer>
                  </Profile>
                </ProfileInfo>
              </Profiles>
            </ResGuest>
          </ResGuests>
          <ResGlobalInfo>
            <TimeSpan End="${departureDate}" Start="${arrivalDate}" />
            <Total AmountAfterTax="10000.00" />
            <BasicPropertyInfo HotelCode="${hotelId}" />
          </ResGlobalInfo>
        </HotelReservation>
      </HotelReservations>
    </OTA_HotelResNotifRQ>
  </soap-env:Body>
</soap-env:Envelope>`

      // Make API call
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/pms/callInplassReservations`,
        soapTemplate,
        {
          headers: {
            'Content-Type': 'application/xml',
          },
        }
      )

      if (response.status === 200) {
        setSuccessMessage('Reservation added successfully!')
        form.resetFields()
        setIsAddModalVisible(false)
      } else {
        setErrorMessage('Failed to add reservation')
      }
    } catch (error) {
      console.error('Error adding reservation:', error)
      setErrorMessage(
        'Failed to add reservation: ' +
          (error.response?.data?.message || error.message)
      )
    } finally {
      setShowLoader(false)
    }
  }

  const handleAddCancel = () => {
    form.resetFields()
    setIsAddModalVisible(false)
  }
  // --- End Modal Handlers ---

  const shouldSendEmail = useCallback((arrivalDate, emailPeriod) => {
    if (!arrivalDate || !emailPeriod) {
      console.log('Missing required data:', { arrivalDate, emailPeriod })
      return false
    }

    const arrival = moment(arrivalDate)
    const now = moment()
    // If it's arrival day setting
    if (emailPeriod.days === 0) {
      const isSameDay = arrival.isSame(now, 'day')
      return isSameDay
    }
    // Calculate when email should be sent
    const emailSendDate = arrival.clone().subtract(emailPeriod.days, 'days')
    const shouldSend = now.isSame(emailSendDate, 'day')

    return shouldSend
  }, [])

  const sendEmail = useCallback(
    async reservation => {
      if (
        processedReservations.has(reservation.UniqueID) &&
        !selectedReservation
      ) {
        console.log('Email already processed for:', reservation.UniqueID)
        return
      }

      // Check if we should send email based on arrival date and configured time period
      if (!shouldSendEmail(reservation.checkinDate, emailTimePeriod)) {
        return
      }

      setShowLoader(true)
      try {
        const variables = generateEmailVariables(reservation)
        const emailTemp = 'pre-checkin'
        const res = await sendEmailByEmailId(
          reservation.email,
          variables,
          emailTemp,
          hotelId
        )
        if (res.success) {
          console.log(`Email sent successfully to ${reservation.email}`)
          setSuccessMessage(res.message)
          if (!selectedReservation) {
            processedReservations.add(reservation.UniqueID)
          }
        } else {
          console.error(`Failed to send email to ${reservation.email}`)
          setErrorMessage(res.message)
        }
      } catch (error) {
        console.error('Error sending email:', error)
        setErrorMessage('Failed to send email')
      } finally {
        setShowLoader(false)
        setSelectedReservation(null)
      }
    },
    [
      hotelId,
      processedReservations,
      selectedReservation,
      emailTimePeriod,
      shouldSendEmail,
    ]
  )

  const handleResendEmail = reservation => {
    setSelectedReservation(reservation)
    setShowConfirmationDialog(true)
    setEmail(reservation.email)
    setVariables(generateEmailVariables(reservation))
    setEmailTemp('pre-checkin')
  }

  useEffect(() => {
    let isMounted = true
    const fetchReservations = async () => {
      if (!hotelId || !hotelInfo?.pmsType) return

      setShowLoader(true)
      try {
        let pmsHotelId = hotelInfo?.pmsHotelId
        const pmsType = hotelInfo?.pmsType
        if (pmsType === 'OPERA CLOUD') {
          const pmsHost = hotelInfo?.operaCloudHostName
          await callOperaCloudReservationCollectionSaveListenerApi(
            pmsHotelId,
            pmsHost
          )
        } else if (pmsType === 'OPERA OWS') {
          await callOperaReservationCollectionSaveListenerApi(hotelInfo)
        }

        if (isMounted) {
          console.log('pmsHotelId ---||||||', pmsHotelId)
          console.log('pmsType ---||||||', pmsType)
          await ReservationCollectionListener(pmsHotelId, pmsType, dispatch)
        }
      } catch (error) {
        console.error(error)
        if (isMounted) {
          setErrorMessage('Failed to fetch reservations.')
        }
      } finally {
        if (isMounted) {
          setShowLoader(false)
        }
      }
    }

    fetchReservations()

    return () => {
      isMounted = false
      unsubscribeReservations(hotelId)
    }
  }, [hotelId, dispatch, hotelInfo])

  useEffect(() => {
    const fetchEmailTimePeriod = async () => {
      try {
        const docRef = db.collection(Collections.HOTELS).doc(hotelId)
        const doc = await docRef.get()
        if (doc.exists) {
          const data = doc.data()
          console.log('Fetched email time period:', data.emailTimePeriod)
          setEmailTimePeriod(data.emailTimePeriod)
        }
      } catch (error) {
        console.error('Error fetching email time period:', error)
      }
    }

    fetchEmailTimePeriod()
  }, [hotelId])

  // useEffect(() => {
  //   console.log(reservations, 'reservations')
  //   const processPendingReservations = async () => {
  //     if (!emailTimePeriod) {
  //       console.log('No email time period configured')
  //       return
  //     }

  //     console.log('Processing reservations:', {
  //       count: reservations.length,
  //       emailTimePeriod,
  //     })

  //     for (const reservation of reservations) {
  //       if (reservation.emailAction === pendingLable) {
  //         console.log('Processing pending reservation:', {
  //           UniqueID: reservation.UniqueID,
  //           checkinDate: reservation.checkinDate,
  //         })
  //         await sendEmail(reservation)
  //       }
  //     }
  //   }

  //   if (reservations.length > 0) {
  //     processPendingReservations()
  //   }
  // }, [reservations, sendEmail, emailTimePeriod])

  const columns = [
    {
      title: 'Sl.No',
      dataIndex: 'index',
      width: 100,
      key: 'index',
      render: (_, __, index) => index + 1,
    },
    {
      title: translateTextI18N('Reservation Id'),
      dataIndex: 'reservationId',
      render: (text, row) => `${row.UniqueID}`,
      width: 150,
    },
    {
      title: translateTextI18N('Booking Reference'),
      dataIndex: 'bookingReference',
      render: (text, row) => `${row.bookingRefrence}`,
      width: 150,
    },
    {
      title: translateTextI18N('Guest Name'),
      dataIndex: 'guestName',
      render: (text, row) => `${row.guestName}`,
      width: 200,
    },
    {
      title: translateTextI18N('Email'),
      dataIndex: 'email',
      render: (text, row) => `${row.email}`,
      width: 220,
    },
    {
      title: translateTextI18N('Arrival Date'),
      dataIndex: 'checkinDate',
      render: (text, row) => `${row.checkinDate}`,
      width: 150,
    },
    {
      title: translateTextI18N('Status'),
      dataIndex: 'status',
      render: (text, row) => `${row.status}`,
      width: 150,
    },
    {
      title: translateTextI18N('Action'),
      dataIndex: 'emailAction',
      width: 150,
      render: (_, row) => {
        const { text, disabled, showButton } = getEmailButtonProps(
          row.emailAction
        )
        return (
          <Space>
            {showButton && (
              <Button
                className={`statusBtn completedBtn`}
                onClick={() => handleResendEmail(row)}
              >
                {translateTextI18N(text)}
              </Button>
            )}
          </Space>
        )
      },
    },
  ]

  const handleConfirmationDialogOk = async () => {
    setShowConfirmationDialog(false)
    if (selectedReservation) {
      await sendEmail(selectedReservation)
    } else {
      try {
        const res = await sendEmailByEmailId(
          email,
          variables,
          emailTemp,
          hotelId
        )
        if (res.success) {
          setSuccessMessage(res.message)
        } else {
          setErrorMessage(res.message)
        }
      } catch (error) {
        console.error('Error sending email:', error)
        setErrorMessage('Failed to send email')
      }
    }
    setShowLoader(false)
  }

  const handleConfirmationDialogCancel = () => {
    setShowConfirmationDialog(false)
    setShowLoader(false)
    setSelectedReservation(null)
  }

  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Reservations'
                breadcrumb={
                  hotelPmsIntergationType === 'Only Pms'
                    ? ['PMS', 'Reservations']
                    : ['Department Admin', 'Front Desk', 'PMS', 'Reservations']
                }
              />
            </div>
            <div className='col-12 col-xl-12'>
              <div className='tablefilter-wrp'>
                <div className='form-row'>
                  <div className='col-6 col-md-4 col-lg'>
                    <div className='cmnSelect-form'>
                      <Select
                        id='reservationFilter'
                        options={reservationIds}
                        value={filters.reservationId || undefined}
                        onChange={value =>
                          setFilters({ ...filters, reservationId: value })
                        }
                        showSearch
                        placeholder='Reservation ID'
                      />
                    </div>
                  </div>

                  <div className='col-6 col-md-4 col-lg'>
                    <div className='cmnSelect-form'>
                      <Select
                        id='bookingReferenceFilter'
                        options={bookingReferences}
                        value={filters.bookingReference || undefined}
                        onChange={value =>
                          setFilters({
                            ...filters,
                            bookingReference: value,
                          })
                        }
                        showSearch
                        placeholder='Booking Reference'
                        allowClear
                      />
                    </div>
                  </div>

                  <div className='col-6 col-md-4 col-lg'>
                    <div className='cmnSelect-form'>
                      <Select
                        id='guestNameFilter'
                        options={guestNames}
                        value={filters.guestName || undefined}
                        onChange={value =>
                          setFilters({ ...filters, guestName: value })
                        }
                        showSearch
                        placeholder='Guest Name'
                        allowClear
                      />
                    </div>
                  </div>

                  <div className='col-6 col-md-4 col-lg'>
                    <div className='cmnSelect-form'>
                      <DatePicker
                        format={dateFormatList}
                        placeholder={translateTextI18N('Arrival Date')}
                        value={filters.arrivalDate}
                        onChange={handleArrivalDateChange}
                        allowClear
                      />
                    </div>
                  </div>

                  <div className='col-6 col-md-auto '>
                    <div className='cmnSelect-form'>
                      <Button
                        id='realIncoReset'
                        type='primary'
                        className='adduserbtn'
                        onClick={resetFilter}
                      >
                        <img
                          src={getImage('images/clearicon.svg')}
                          alt=''
                        ></img>
                      </Button>
                    </div>
                  </div>

                  <div className='col-6 col-md-auto'>
                    <Button
                      type='primary'
                      className='blueBtn'
                      onClick={showAddModal}
                      disabled={showLoader}
                    >
                      {translateTextI18N('Add Reservation')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-xl-12'>
              <div className='row ml-2 mb-2' id='frontDeskAlerts'>
                <CustomAlert
                  visible={!!successMessage}
                  message={successMessage}
                  type='success'
                  showIcon={true}
                  onClose={() => setSuccessMessage('')}
                />
                <CustomAlert
                  visible={!!errorMessage}
                  message={errorMessage}
                  type='error'
                  showIcon={true}
                  onClose={() => setErrorMessage('')}
                />
              </div>
              <div className='row' id='frontDeskData'>
                <div className='col-12 col-xl-12'>
                  <div className='table-wrp'>
                    <Table
                      columns={columns}
                      dataSource={filteredReservations}
                      loading={showLoader}
                      pagination={PaginationOptions}
                      scroll={{ y: 580 }}
                      rowKey='id'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ConfirmationDialog
        visible={showConfirmationDialog}
        onCancelClick={handleConfirmationDialogCancel}
        onOkClick={handleConfirmationDialogOk}
        title={`Send Pre-checkin Email`}
        message={`Are you sure you want to send pre-checkin email to the guest`}
        okButtonText='Confirm'
      />
      <Modal
        className='cmnModal requestModal'
        title={translateTextI18N('Add New Reservation')}
        visible={isAddModalVisible}
        onCancel={handleAddCancel}
        destroyOnClose
        footer={null}
        width={600}
      >
        <Form
          form={form}
          layout='vertical'
          name='add_reservation_form'
          onFinish={handleAddOk}
          initialValues={{}}
        >
          <div className='row'>
            <div className='col-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  name='guestName'
                  label={translateTextI18N('Guest Name')}
                  rules={[
                    {
                      required: true,
                      message: 'Please input the guest name!',
                    },
                  ]}
                >
                  <Input placeholder='Enter guest name' />
                </Form.Item>
              </div>
            </div>
            <div className='col-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  name='bookingReference'
                  label={translateTextI18N('Booking Reference ')}
                  rules={[
                    {
                      required: true,
                      message: 'Please input the booking reference!',
                    },
                  ]}
                >
                  <Input placeholder='Enter booking reference' />
                </Form.Item>
              </div>
            </div>

            <div className='col-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  name='email'
                  label={translateTextI18N('Email')}
                  rules={[
                    { required: true, message: 'Please input the email!' },
                    { type: 'email', message: 'Please enter a valid email!' },
                  ]}
                >
                  <Input placeholder='Enter email address' />
                </Form.Item>
              </div>
            </div>

            <div className='col-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  name='arrivalDate'
                  label={translateTextI18N('Arrival Date')}
                  rules={[
                    {
                      required: true,
                      message: 'Please select the arrival date!',
                    },
                  ]}
                >
                  <DatePicker
                    format={dateFormatList}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className='modalFooter mt-3'>
            <Button
              key='back'
              className='grayBtn'
              onClick={handleAddCancel}
              disabled={showLoader}
            >
              {translateTextI18N('Cancel')}
            </Button>
            <Button
              key='submit'
              className='blueBtn ml-3 ml-lg-4'
              type='primary'
              htmlType='submit'
              loading={showLoader}
              disabled={showLoader}
            >
              {translateTextI18N('Save')}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default PMSreserve
