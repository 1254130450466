import React, { useContext, useEffect, useState, useMemo } from 'react'
import {
  Table,
  Button,
  message,
  Modal,
  Select,
  Form,
  DatePicker,
  Space,
} from 'antd'
import Header from '../../../Common/Header/Header'
import SideMenu from '../../../Common/Sidemenu/Sidemenu'
import { useDispatch, useSelector } from 'react-redux'
import {
  AcceptRejectCheckIn,
  AddGuestListener,
} from '../../../../services/guest'
import PageNamecard from '../../../Common/PageNameCard/PageNameCard'
import { GetCurrentUser } from '../../../../services/user'
import {
  PaginationOptions,
  pendingLable,
  rejectedLable,
  secondsToShowAlert,
} from '../../../../config/constants'
import CustomAlert from '../../../Common/CustomAlert/CustomAlert'
import ConfirmationDialog from '../../../Common/ConfirmationDialog/ConfirmationDialog'
import crypto from 'crypto-js'
import { useCustomI18NTranslatorHook } from '../../../../utility/globalization'
import { AdminRequest } from '../../../../services/notification'
import { AuthContext } from '../../../../Router/AuthRouteProvider'
import {
  getCommonColumns,
  sanitiseImages,
  getImage,
} from '../../../../config/utils'
import {
  sendEmail,
  acceptRejectPmsCheckin,
  preCheckinRequestCollectionListener,
  unsubscribePreCheckinRequests,
  sendEmailByEmailId,
} from '../../../../services/pms'
import MenuGallery from '../../Restaurant/MenuGallery'
import moment from 'moment'

const GetButtonTextAndClass = row => {
  const disabled = row.status !== pendingLable ? 'disabled' : ''
  let acceptButtonText = 'ACCEPT'
  let rejectButtonText = 'REJECT'
  if (row.status !== pendingLable) {
    acceptButtonText = ''
    rejectButtonText = ''
    if (row.status === rejectedLable) {
      rejectButtonText = 'REJECTED'
    } else {
      acceptButtonText = 'ACCEPTED'
    }
  }
  return { disabled, acceptButtonText, rejectButtonText }
}
const PMScheckin = () => {
  const { hotelId, userInfo } = useContext(AuthContext)
  const [showLoader, setShowLoader] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [requestId, setRequestId] = useState(false)
  const [requestRoomNumber, setRequestRoomNumber] = useState('')
  const [email, setEmail] = useState('')
  const [guestName, setGuestName] = useState('')
  const [reservationId, setReservationId] = useState('')
  const [requestReferenceId, setReferenceId] = useState('')
  const [accepted, setAccepted] = useState(false)
  const [confirmationTitle, setConfirmationTitle] = useState('')
  const [confirmationMessage, setConfirmationMessage] = useState('')
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [guestId, setGuestId] = useState('')
  const preCheckinRequestsData = useSelector(state => state.preCheckinRequests)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const { checkInCheckOutRequests, loadingGuests } = useSelector(state => state)
  const [isImageViewClicked, setIsImageViewClicked] = useState(false)
  const { hotelInfo } = useSelector(state => state)
  const [isIdImageModalVisible, setIsIdImageModalVisible] = useState(false)
  const [selectedIdImage, setSelectedIdImage] = useState(null)
  const [isImageLoading, setIsImageLoading] = useState(true)
  const [guestDetails, setGuestDetails] = useState({
    firstName: '',
    lastName: '',
    bookingReference: '',
    roomNumber: '',
  })
  // State for unique booking references and guest names
  const [bookingReferences, setBookingReferences] = useState([])
  const [guestNames, setGuestNames] = useState([])
  const dispatch = useDispatch()

  // State for filter values
  const [filters, setFilters] = useState({
    bookingReference: '',
    guestName: '',
    arrivalDate: null,
  })

  // Date format for DatePicker
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY']

  const hotelPmsIntergationType = hotelInfo?.pmsIntergationType

  // Fetch unique booking references and guest names
  useEffect(() => {
    if (preCheckinRequestsData && preCheckinRequestsData.length > 0) {
      const uniqueBookingReferences = [
        ...new Set(preCheckinRequestsData.map(res => res.bookingReferance)),
      ].map(ref => ({ label: ref, value: ref }))

      const uniqueGuestNames = [
        ...new Set(preCheckinRequestsData.map(res => res.guestName)),
      ].map(name => ({ label: name, value: name }))

      setBookingReferences(uniqueBookingReferences)
      setGuestNames(uniqueGuestNames)
    }
  }, [preCheckinRequestsData])

  // Filter preCheckinRequestsData based on filter values
  const filteredRequests = useMemo(() => {
    if (!preCheckinRequestsData) return []

    return preCheckinRequestsData.filter(request => {
      const arrivalDateMatch = filters.arrivalDate
        ? moment(request.checkin).isSame(filters.arrivalDate, 'day')
        : true

      return (
        (!filters.bookingReference ||
          request.bookingReferance.includes(filters.bookingReference)) &&
        (!filters.guestName || request.guestName.includes(filters.guestName)) &&
        arrivalDateMatch
      )
    })
  }, [preCheckinRequestsData, filters])

  // Reset filters
  const resetFilter = () => {
    setFilters({
      bookingReference: '',
      guestName: '',
      arrivalDate: null,
    })
  }

  const handleArrivalDateChange = date => {
    setFilters({ ...filters, arrivalDate: date })
  }

  const handleGalleryClick = () => {
    setIsImageViewClicked(true)
    setTimeout(() => {
      setIsImageViewClicked(false)
    }, 5000)
  }
  useEffect(() => {
    const fetchPreCheckinRequests = async () => {
      setShowLoader(true)
      try {
        // need to pass pms type so we can laod the requests based upon pms type.
        const pmsType = hotelInfo?.pmsType
        await preCheckinRequestCollectionListener(hotelId, pmsType, dispatch)
      } catch (error) {
        setErrorMessage('Failed to fetch Requests.')
      } finally {
        setShowLoader(false)
      }
    }
    fetchPreCheckinRequests()
    return () => {
      unsubscribePreCheckinRequests(hotelId)
    }
  }, [hotelId, dispatch, hotelInfo])

  const [translateTextI18N] = useCustomI18NTranslatorHook()
  useEffect(() => {
    AddGuestListener(hotelId, dispatch)
  }, [dispatch, hotelId])
  const setMessage = (setMessageFunc, messageText) => {
    setMessageFunc(translateTextI18N(messageText ?? ''))
    setTimeout(() => setMessageFunc(''), secondsToShowAlert)
  }
  const acceptRejctClick = async (
    id,
    roomNumber,
    email,
    referenceId,
    guestName,
    uniqueId,
    action,
    title,
    messageText
  ) => {
    setRequestId(id)
    setRequestRoomNumber(roomNumber)
    setEmail(email)
    setReferenceId(referenceId)
    setGuestName(guestName)
    setReservationId(uniqueId)
    setAccepted(action)
    setConfirmationTitle(title)
    setConfirmationMessage(messageText)
    setShowConfirmationDialog(true)
    // callCheckIn()
  }
  const acceptRejectCheckIn = async () => {
    try {
      if (showLoader) return
      setShowConfirmationDialog(false)
      setShowLoader(true)

      const { success, message: acceptCheckInMessage } =
        await acceptRejectPmsCheckin(
          hotelId,
          requestId,
          accepted,
          email,
          preCheckinRequestsData,
          hotelInfo
        )

      console.log('success', success)

      if (success) {
        let status = accepted ? 'accepted' : 'rejected'
        let templateName = accepted
          ? 'ACCEPTED_CHECKIN_STATUS'
          : 'REJECTED_CHECKIN_STATUS'

        const userid = GetCurrentUser().uid
        let template_variables = { '%status%': status }

        console.log(template_variables, 'template_variables')

        console.log(templateName, "template name");
        

        AdminRequest(
          templateName,
          template_variables,
          userid,
          requestId,
          hotelId,
          requestReferenceId,
          null,
          userInfo.departmentId
        )

        setMessage(setSuccessMessage, acceptCheckInMessage)
      } else {
        setMessage(setErrorMessage, acceptCheckInMessage)

        // Send email when the response is unsuccessful
        const queryParams = `hotel=${encodeURIComponent(
          'our-hotel-resort-spa-dei'
        )}&uniqueId=${encodeURIComponent(reservationId)}`

        const encryptedParams = crypto.AES.encrypt(
          queryParams,
          'pms-precheckin-key'
        ).toString()

        const emailVariables = {
          '%name%': guestName,
          '%link%': `https://dev-guest.inplass.online/prechecking?${encryptedParams}`,
        }

        console.log('Sending email due to failure:', emailVariables)

        await sendEmailByEmailId(
          email,
          emailVariables,
          'autoCheckinReject',
          hotelId
        )
      }
    } catch (error) {
      message.error(error.message || 'Something went wrong! Please try again!')
    } finally {
      setShowLoader(false)
    }
  }

  const handleIdImageView = idImageUrl => {
    setIsImageLoading(true)
    setSelectedIdImage(idImageUrl)
    setIsIdImageModalVisible(true)
  }

  const {
    // guestFullName,
    bookingReferanceCol,
    submittedTimeCol,
  } = getCommonColumns({
    translateTextI18N,
  })
  bookingReferanceCol.width = 130
  // guestFullName.width = 170
  const frontDeskColumns = [
    {
      title: 'Sl.No',
      dataIndex: 'index',
      width: 90,
      key: 'index',
      render: (_, __, index) => index + 1,
    },
    {
      title: translateTextI18N('Guest Name'),
      dataIndex: 'guestName',
      width: 170,
    },
    bookingReferanceCol,
    // use this in case of api based adhaaar verification
    // {
    //   title: translateTextI18N('Id Number'),
    //   dataIndex: 'verificationIdNumber',
    //   width: 100,
    // },
    // {
    //   title: translateTextI18N('Id Name'),
    //   dataIndex: 'verificationName',
    //   width: 100,
    // },
    {
      title: translateTextI18N('ID Verification'),
      dataIndex: 'identification',
      width: 120,
      render: (_, row) => {
        return row.identification?.url ? (
          <Button
            type='primary'
            onClick={() => handleIdImageView(row.identification.url)}
          >
            View ID
          </Button>
        ) : (
          <span>No ID uploaded</span>
        )
      },
    },
    {
      title: translateTextI18N('Check-In Date'),
      dataIndex: 'checkin',
      width: 100,
    },
    {
      title: translateTextI18N('Check-In Time'),
      dataIndex: 'arrival',
      width: 100,
    },
    {
      title: translateTextI18N('Accompanying Guest'),
      dataIndex: 'additionalGuestName',
      width: 170,
    },
    submittedTimeCol,
    {
      title: translateTextI18N('Status'),
      dataIndex: 'Status',
      className: '',
      width: 240,
      render: (_, row) => {
        const { disabled, acceptButtonText, rejectButtonText } =
          GetButtonTextAndClass(row)
        const { guestId } = row
        return (
          <div>
            <Button
              className={`statusBtn completedBtn ${disabled}`}
              onClick={() => {
                if (!disabled) {
                  acceptRejctClick(
                    row.id,
                    row.roomNumber,
                    row.email,
                    row.bookingReferance,
                    row.guestName,
                    row.uniqueId,
                    true,
                    'Accept Guest',
                    `Are you sure you want to accept the guest`
                  )
                }
              }}
            >
              {translateTextI18N(acceptButtonText)}
            </Button>
            <Button
              className={`statusBtn rejectBtn ml-2 ${disabled}`}
              onClick={() => {
                if (!disabled) {
                  acceptRejctClick(
                    row.id,
                    row.roomNumber,
                    row.email,
                    row.bookingReferance,
                    row.guestName,
                    row.uniqueId,
                    false,
                    'Reject Guest',
                    `Are you sure you want to reject this request ?`
                  )
                }
              }}
            >
              {translateTextI18N(rejectButtonText)}
            </Button>
          </div>
        )
      },
    },
  ]
  return (
    <>
      <Header></Header>
      <SideMenu></SideMenu>
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Pre Check-In'
                breadcrumb={
                  hotelPmsIntergationType === 'Only Pms'
                    ? ['PMS', 'Pre Check-In']
                    : ['Department Admin', 'Front Desk', 'PMS', 'Pre Check-In']
                }
              />
            </div>

            <div className='col-12 col-xl-12'>
              <div className='tablefilter-wrp'>
                <div className='form-row'>
                  <div className='col-6 col-md-4 col-lg'>
                    <div className='cmnSelect-form'>
                      <div className='row mb-3'>
                        <div className='col-6 col-md-4 col-lg'>
                          <Select
                            id='bookingReferenceFilter'
                            options={bookingReferences}
                            value={filters.bookingReference || undefined}
                            onChange={value =>
                              setFilters({
                                ...filters,
                                bookingReference: value,
                              })
                            }
                            showSearch
                            placeholder='Booking Reference'
                            style={{ width: '100%' }}
                          />
                        </div>

                        <div className='col-6 col-md-4 col-lg'>
                          <Select
                            id='guestNameFilter'
                            options={guestNames}
                            placeholder='Guest Name'
                            value={filters.guestName || undefined} // Use undefined when empty to show placeholder
                            onChange={value =>
                              setFilters({ ...filters, guestName: value })
                            }
                            showSearch
                            allowClear
                            style={{ width: '100%' }}
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </div>

                        <div className='col-6 col-md-4 col-lg'>
                          <div className='cmnSelect-form'>
                            <DatePicker
                              format={dateFormatList}
                              placeholder='Arrival Date'
                              value={filters.arrivalDate}
                              onChange={handleArrivalDateChange}
                              style={{ width: '100%' }}
                            />
                          </div>
                        </div>

                        <div className='col-6 col-md-auto'>
                          <div className='cmnSelect-form'>
                            <Button
                              id='realIncoReset'
                              type='primary'
                              className='adduserbtn'
                              onClick={resetFilter}
                            >
                              <img
                                src={getImage('images/clearicon.svg')}
                                alt='Clear'
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-xl-12'>
              <div className='row ml-2 mb-2' id='frontDeskAlerts'>
                <CustomAlert
                  visible={successMessage}
                  message={successMessage}
                  type='success'
                  showIcon={true}
                />
                <CustomAlert
                  visible={errorMessage}
                  message={errorMessage}
                  type='error'
                  showIcon={true}
                />
              </div>
              <div className='row' id='frontDeskData'>
                <div className='col-12 col-xl-12'>
                  <div className='table-wrp'>
                    <Table
                      //need to add arrival time of guest in  the coloumns and add serial number.
                      columns={frontDeskColumns}
                      dataSource={filteredRequests}
                      pagination={PaginationOptions}
                      scroll={{ y: 580 }}
                      loading={showLoader || loadingGuests}
                      rowKey='id'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ConfirmationDialog
        visible={showConfirmationDialog}
        onCancelClick={() => setShowConfirmationDialog(false)}
        onOkClick={acceptRejectCheckIn}
        title={confirmationTitle}
        message={confirmationMessage}
        okButtonText='Confirm'
      />
      <Modal
        title='ID Verification'
        visible={isIdImageModalVisible}
        onCancel={() => {
          setIsIdImageModalVisible(false)
          setSelectedIdImage(null)
          setIsImageLoading(true)
        }}
        footer={null}
        width={800}
      >
        {selectedIdImage && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {isImageLoading && (
              <div style={{ textAlign: 'center', padding: '20px' }}>
                Loading...
              </div>
            )}
            <img
              src={selectedIdImage}
              alt='ID Verification'
              style={{
                maxWidth: '100%',
                maxHeight: '80vh',
                display: isImageLoading ? 'none' : 'block',
              }}
              onLoad={() => setIsImageLoading(false)}
            />
          </div>
        )}
      </Modal>
    </>
  )
}
export default PMScheckin
